<template>
    <div>
        <!-- <b-form-group label="บัญชี">
            <b-form-select
                v-model="form.bookbank_selected"
                :options="bookbank_options"
                @input="loadData"
            
            >
                <template>  
                </template>
            </b-form-select>
        </b-form-group>
        <b-form-group label="วันที่">
            <date-picker
                v-model="form.date_selected"
                :masks="{ input: 'D/M/YYYY' }"
                :model-config="modelConfig"
                mode = 'date'
                @input="filter"
            >
                <template v-slot="{ inputValue, togglePopover }">
                    <b-form-group>
                        <b-form-input
                            class="border px-2 py-1 rounded"
                            :value="inputValue"
                            @click="togglePopover()"
                            autocomplete="off"
                        />
                    </b-form-group>
                </template>
            </date-picker>
        </b-form-group> -->

        <date-account-selector @selected="date_account"></date-account-selector>


        <h1>Drive</h1>
        <b-card no-body>
            <b-table striped hover :items="items" :fields="fields" responsive="lg">
                <template #cell(date)="data">
                    {{data.item.createdAt | time}}
                </template>
                <template #cell(full_name)="data">
                    <div v-if="data.item.member">
                        {{data.item.member.first_name}} {{data.item.member.last_name}} 
                    </div>
                </template>
                <template #cell(start_date)="data">
                    {{data.item.order.start_date | day }} - {{data.item.order.end_date | day}}
                </template>
                <template #cell(book_in)="data">
                    <div v-if="data.item.main && data.item.side==='deposit'">
                        {{data.item.amount}}
                    </div>
                </template>
                <template #cell(book_out)="data">
                    <div v-if="data.item.main && data.item.side==='withdraw'">
                        {{data.item.amount}}
                    </div>
                </template>
                <template #cell(back_in)="data">
                    <div v-if="!data.item.main && data.item.side==='deposit'">
                        {{data.item.amount}}
                    </div>
                </template>
                <template #cell(back_out)="data">
                    <div v-if="!data.item.main && data.item.side==='withdraw'">
                        {{data.item.amount}}
                    </div>
                </template>
                <template #cell(payment_type)="data">
                    {{data.item.payment_type | payment_type}}
                </template>
            </b-table>
        </b-card>   
    </div>
</template>

<script>
import { BLink,BCard,BRow,BCol,BButton,BTable,BFormGroup,BFormInput,BFormSelect } from "bootstrap-vue";
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DateAccountSelector from './component/DateAccountSelector.vue'

import moment from 'moment'
export default {
    components:{
        BLink,
        BCard,
        BRow,
        BCol,
        BButton,
        BTable,
        BFormGroup,
        BFormInput,
        // DatePicker,
        BFormSelect,
        DateAccountSelector
    },
    data(){
        return{
            bookbank_options: [
                { value: null, text: 'Please select an option' },
            ],
            items:[],
            fields: [
                {
                    key: 'date',
                    label: 'Date',
                },
                {
                    key: 'full_name',
                    label: 'Name',
                },
                {
                    key: 'start_date',
                    label: 'วันที่รับ',
                },
                {
                    key: 'book_in',
                    label: 'เข้า (บัญชี)',
                },
                {
                    key: 'book_out',
                    label: 'ออก (บัญชี)',
                },
                {
                    key: 'back_in',
                    label: 'เข้า (ภายใน)',
                },
                {
                    key: 'back_out',
                    label: 'ออก (ภายใน)',
                },
                {
                    key: 'skip',
                    label: 'SKIP',
                },
                {
                    key: 'payment_type',
                    label: 'ประเภท',
                }
            ],
            form:{
                date_selected:"2022-08-16",
                bookbank_selected:"62ee919b839b3103a736801c",
            },
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
        }
        
    },
    created() {
        this.loadData()
        this.loadBookbank()
    },
    methods:{
        loadData(){
            console.log('load')
            this.$http({
                method: 'POST',
                url: `/account/drive/day`,
                data:this.form
            }).then(x => {
                // console.log(x.data.data)
                this.items = x.data.data
            })
        },
        loadBookbank(){
            this.$http({
                method: 'GET',
                url: `/bookbank/list`,
            }).then(x => {
                this.bookbank_options = x.data.data.mix
            })
        },  
        date_account(x){
            this.form.date_selected = x.date_selected
            this.form.bookbank_selected = x.bookbank_selected
            this.loadData()
        }
    },
    filters:{
        number: function (x) {
            if(x!=null){
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }else{
                return x
            }
        },
        account_no: function (phone) {
            return phone.replace(/(\d{3})(\d{1})(\d{5})(\d+)/, '$1-$2-$3-$4');
        },
        time: function (date) {
            return moment(date).format('HH:mm');
        },
        day: function (date) {
            return moment(date).format('D/M/YY');
        },
        payment_type: function (x){
            if(x === 'deposit'){
                return "มัดจำ"
            }else if( x === 'rental'){
                return "รายรับ"
            }else if( x === 'shipping'){
                return "ค่าส่ง"
            }else{
                return x
            }
        }
    }
}
</script>